/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.source-logo {
  max-width: 40%;
  max-height: 10rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

a.user-mention {
  display: inline-block;
  background: #EBECF0;
  padding: 1px 7px 3px 4px;
  border-radius: 0.5em;
  border: 0;
  line-height: 1em;
  white-space: nowrap;
  color: #42526E;
  text-decoration: none;  
}

.segment {
  padding-left: '2rem';
  padding-right: '2rem'; 
  padding-top: '1rem'; 
  padding-bottom: '1rem'; 
  min-width: '200px'; 
  max-width: '800px'; 
  width: "80%"; 
  margin-left: 'auto'; 
  margin-right: 'auto'
}